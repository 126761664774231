import React, { useState } from "react";
import { errorToast, infoToast, successToast } from "../../../utils/Toast";
import { apiRequest } from "../../../utils/api";
import { numberSpacing } from "../../../utils/function";
import { isTest } from "../../../utils/storage";

function RegistrationForm({ cart, manufactured }) {
  let info = localStorage.getItem("plate-info");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [county, setCounty] = useState("");
  const [pin, setPin] = useState("");
  const [nTitle, setNTitle] = useState("");
  const [nName, setNName] = useState("");
  const [nLastName, setNLastName] = useState("");
  const [isCondition, setIsCondition] = useState(false);
  const [userId, setUserId] = useState("");

  async function register(e) {
    e.preventDefault();

    try {
      if (localStorage.getItem("uuid")) {
        return;
      }
      // validations
      if (!isCondition) return infoToast("Please Accept Purchase");
      if (
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !address ||
        !town ||
        !county ||
        !pin
      )
        return infoToast("Please Fill All Fields");

      let payload = {
        firstname: firstName,
        surname: lastName,
        email,
        phone,
        address,
        town,
        county,
        postcode: pin,
        nominee_title: nTitle,
        nominee_name: nName,
        nominee_surname: nLastName,
      };

      if (isTest()) {
        payload.test_order = "test";
      }
      let res = await apiRequest.post(`/customer/register`, payload);
      if (res.data.status === 200) {
        successToast("Registered Successfully");
        localStorage.setItem("uuid", res.data.customer_id);
        setUserId(res.data.customer_id);
        // for next step
        document.getElementById("nextStep").click();
      } else {
        infoToast(res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <span>1</span>Billing Details
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="billing-details-contant">
              <form onSubmit={register}>
                <div className="biling-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputName">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First name"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputSurname">Surname</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputSurname"
                          placeholder="Surname"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPhone">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPhone"
                          placeholder="Phone Number"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputaddress">
                          Start Typing your address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputaddress"
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputTown">Town</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputTown"
                          placeholder="Town"
                          onChange={(e) => setTown(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputCounty">County</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputCounty"
                          placeholder="County"
                          onChange={(e) => setCounty(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Postcode</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Postcode"
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="biling-title">
                        <h2>Nominee Details</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row nomis-row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputTitle">Title</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setNTitle(e.target.value)}
                        >
                          <option selected="">Choose</option>
                          <option value={"mr"}>Mr.</option>
                          <option value={"miss"}>Miss.</option>
                          <option value={"mrs"}>Mrs.</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          First name
                        </label>
                        <input
                          onChange={(e) => setNName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Surname</label>
                        <input
                          type="text"
                          onChange={(e) => setNLastName(e.target.value)}
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Surname"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="biling-title">
                        <h2>Vehicle Suitability</h2>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={setIsCondition}
                            id="flexCheckDefault10"
                            onChange={(e) => setIsCondition(!isCondition)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault10"
                          >
                            I understand that registration{" "}
                            {numberSpacing(cart.category, cart.fullNumber)} is
                            only suitable for vehicles manufactured{" "}
                            {info ? info : manufactured + "."} By proceeding to
                            purchase I agree to the terms stated here.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="back-button">
                  <button type="submit" className="btn btn-warning">
                    Next
                    <span>
                      <img src="/img/arrow-right.png" />
                    </span>
                  </button>
                  <button
                    className="d-none"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    id="nextStep"
                  >
                    a
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegistrationForm;
