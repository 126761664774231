import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorToast, infoToast } from "../../../utils/Toast";
import { apiRequest } from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCoupon,
  updateFastCoupon,
  updatePlateServiceData,
  updateServiceCoupon,
} from "../../../store/slices/cartSlice";
import { numberSpacing } from "../../../utils/function";
import { updatePlateData } from "../../../store/slices/plateTypeSlice";
import { buylineEnable, getData, isTest } from "../../../utils/storage";
import { transformStringToJDM } from "../../../utils/string";

function LastStep({ cart }) {
  const navigate = useNavigate();

  const [showDiv, setShowDiv] = useState(false);
  const [buttonText, setButtonText] = useState("Enter it here");
  const [couponName, setCouponName] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [conditions, setConditions] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [couponError, setCouponError] = useState("");
  const dispatch = useDispatch();
  const plateType = useSelector((state) => state?.plateType?.data);
  const isFinance = useSelector((state) => state?.finance?.data?.isBuyline);
  const resBuyline = useSelector((s) => s.buyline.data.enable);
  const isBuylineEnable = resBuyline;
  const handleClick = () => {
    // setShowDiv(true);

    setShowDiv(!showDiv);
    if (showDiv) {
      setButtonText("Enter it here");
    } else {
      setButtonText("Cancel");
    }
  };

  async function checkout() {
    if (!conditions) return infoToast("Please Accept Terms And Conditions ");
    try {
      let payload = {
        order_by: localStorage.getItem("uuid"),
        order_fullnumber: cart.fullNumber,
        order_category: cart.category,
        order_price: cart.price,
        order_coupon: couponName.toLowerCase(),
        order_discount: couponValue,
        order_vat: cart.vat,
        order_type: cart.fastCheckout,
        order_dvlafee: cart.dvlaFee,
        order_total: cartTotal,
        order_status: "pending",
        plate_type: cart.plate_type_value,
        cart,
        payment_type: "stripe",
      };

      let res = await apiRequest.post(`/order/place-order`, payload);
      if (res.data.status === 200) {
        localStorage.setItem("order_id", res.data.order_id);

        let payload = {
          name: cart.fullNumber,
          order_id: res.data.order_id,
          total: cartTotal,
          user_id: localStorage.getItem("uuid"),
        };

        let result = await apiRequest.post(`/checkout/checkout`, {
          details: payload,
        });

        window.location.href = result.data.url;

        // navigate("/thanks");
      } else {
        errorToast(res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  async function payWithFinance() {
    if (!conditions) return infoToast("Please Accept Terms And Conditions ");
    try {
      let orderPayload = {
        order_by: localStorage.getItem("uuid"),
        order_fullnumber: cart.fullNumber,
        order_category: cart.category,
        order_price: cart.price,
        order_coupon: couponName.toLowerCase(),
        order_discount: couponValue,
        order_vat: cart.vat,
        order_type: cart.fastCheckout,
        order_dvlafee: cart.dvlaFee,
        order_total: cartTotal,
        order_status: "pending",
        plate_type: cart.plate_type_value,
        cart,
        payment_type: "buyline",
      };

      if (isTest()) {
        orderPayload.test_order = "test";
      }

      let res = await apiRequest.post(`/order/place-order`, orderPayload);
      if (res.data.status === 200) {
        localStorage.setItem("order_id", res.data.order_id);

        const buylinePayload = {
          amount: cartTotal,
          number: transformStringToJDM(cart.fullNumber),
          order_coupon: couponName.toLowerCase(),
          order_discount: couponValue,
          order_by: localStorage.getItem("uuid"),
          order_id: res.data.order_id,
        };
        if (isTest()) {
          buylinePayload.test_order = "test";
        }
        const details = await apiRequest.post(
          `/buyline/checkout`,
          buylinePayload
        );

        if (details.data.status == 200) {
          window.location.href = details.data.message.url;
        } else {
          errorToast(res.data.message);
        }
      } else {
        errorToast(res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  async function subCoupon() {
    console.log(plateType);
    try {
      let res = await apiRequest.get(
        `/coupon/apply/${couponName.toLowerCase()}`
      );

      if (res.data.status === 200) {
        setCoupon(res.data.message);
        setCouponType(res.data.message?.coupon_type);
        if (res.data.message?.coupon_type == "plate") {
          setCouponValue(
            (res.data.message.percentage / 100) * parseFloat(cart.price)
          );
          dispatch(
            updateCoupon({
              coupon: couponName,
              coupon_type: "plate",
              coupon_value:
                (res.data.message?.percentage / 100) * parseFloat(cart?.price),
            })
          );
          setShowDiv(false);
          setButtonText("Edit");
        } else if (res.data.message?.coupon_type == "fast") {
          if (!cart.fastCheckout) {
            setCouponError("This coupon is valid when fast check choosed");
          } else {
            dispatch(updatePlateServiceData({ plate_type: plateType.price }));
            dispatch(
              updateFastCoupon({
                coupon: couponName,
                coupon_type: "fast",
                coupon_value:
                  (res.data.message?.percentage / 100) *
                  parseFloat(cart?.fastCheckout),
              })
            );
            setShowDiv(false);
            setButtonText("Edit");
          }
        } else if (res.data.message?.coupon_type == "service") {
          if (!cart.plate_type_value) {
            setCouponError("This is valid when plate type is choosed");
          } else {
            dispatch(updatePlateServiceData({ fastCheckout: 24.98 }));
            dispatch(
              updateServiceCoupon({
                coupon: couponName,
                coupon_type: "service",
                plate_type: cart.plate_type,
                coupon_value:
                  (res.data.message?.percentage / 100) *
                  parseFloat(cart?.plate_type),
              })
            );

            setShowDiv(false);
            setButtonText("Edit");
          }
        } else {
          if (!cart.plate_type) {
            setCouponError(
              "This coupon is applicable when plates custom choosed"
            );
          }
        }

        console.log(res.data.message?.coupon_type);
        // universal code
        // dispatch(
        //   updateCoupon({
        //     coupon: couponName,
        //     coupon_type: res.data.message?.coupon_type,
        //     coupon_value: cart.plate_type
        //       ? 0
        //       : (res.data.message?.percentage / 100) * parseFloat(cart?.price),
        //   })
        // );
      } else {
        setCoupon(null);
        setCouponValue(0);
        dispatch(
          updateCoupon({
            coupon: null,
            coupon_value: null,
          })
        );
        errorToast("1" + res.data.message);
      }
    } catch (error) {
      errorToast(error.message);
    }
  }

  let singleTotal = parseFloat(cart.price) - parseFloat(couponValue);
  let cartTotal = (
    singleTotal +
    parseFloat(cart.dvlaFee) +
    parseFloat(cart.vat) +
    parseFloat(cart.fastCheckout) +
    parseFloat(cart.plate_type)
  ).toFixed(2);
  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <span>3</span>Payment & Summary
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="billing-details-contant">
              <div className="order-summary">
                <div className="order-summary-title">
                  <h2>Order Summary</h2>
                </div>
                <ul className="order-summary-list">
                  <li>
                    <h3>
                      Registration{" "}
                      {numberSpacing(cart.category, cart.fullNumber)}
                    </h3>
                    <span>£{parseFloat(cart.price).toFixed(2)}</span>
                  </li>
                  <li>
                    <h3>VAT</h3>
                    <span>£{parseFloat(cart.vat).toFixed(2)}</span>
                  </li>
                  <li>
                    <h3>Compulsory DVLA Fee</h3>
                    <span>£{cart.dvlaFee}</span>
                  </li>
                  {cart?.fastCheckout ? (
                    <li>
                      <h3>Registration Transfer Service</h3>
                      <span>£{cart.fastCheckout.toString().slice(0, 5)}</span>
                    </li>
                  ) : null}
                  <li>
                    <h3>Physical Number Plate(s)</h3>
                    <span>£{parseFloat(cart.plate_type).toFixed(2)}</span>
                  </li>

                  <li>
                    <div className="promofield">
                      <div className="promoTitle">
                        <h3>
                          {coupon ? coupon.coupon : "Have a promo code?"}
                          <a onClick={handleClick}>{buttonText}</a>
                        </h3>
                        <span>£{parseFloat(cart.coupon_value).toFixed(2)}</span>
                      </div>
                      {showDiv && (
                        <div className="promo-input">
                          <input
                            type="text"
                            onChange={(e) => setCouponName(e.target.value)}
                            className="form-control"
                            value={couponName}
                          />
                          <button
                            type="button"
                            onClick={subCoupon}
                            className="btn apply-btn"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                      {couponError && (
                        <p className="text-danger">{couponError}</p>
                      )}
                    </div>
                  </li>
                  <li>
                    <h2>
                      Total:<span>£{cartTotal}</span>
                    </h2>
                  </li>
                </ul>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => setConditions(!conditions)}
                    defaultValue={conditions}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and agree to the{" "}
                    <a
                      href="https://www.jdmplates.co.uk/terms-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
                {/* <div className="order-summary-title">
                  <h2>Payment Method</h2>
                </div> */}
                {/* <div className="debit-card">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Debit / Credit Card
                    </label>
                  </div>
                  <div className="debit-card-img">
                    <img src="/img/card.png" />
                  </div>
                </div> */}
                {/* <div className="debit-numbar">
                  <ul>
                    <li>
                      <input
                        type="text"
                        className="ccFormatMonitor"
                        placeholder="Card Number"
                      />
                    </li>
                    <li>
                      {" "}
                      <input
                        type="text"
                        id="inputExpDate"
                        placeholder="MM / YY"
                        maxLength={7}
                      />
                      <input
                        type="password"
                        className="cvv"
                        placeholder="CVV"
                      />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {isBuylineEnable && parseFloat(cartTotal) > 250 && (
              <div className="view-button-form outline">
                <button
                  type="button"
                  onClick={payWithFinance}
                  className="btn btn-dark"
                >
                  Pay By Finance
                  <span>
                    <img src="/img/arrow-right.png" />
                  </span>
                </button>
              </div>
            )}
            <div className="back-button">
              <button
                type="button"
                onClick={checkout}
                className="btn btn-warning"
              >
                Pay Now
                <span>
                  <img src="/img/arrow-right.png" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LastStep;
