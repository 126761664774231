import useFechBuyline from "../hooks/useFetchBuyline";
import { fetchBuyline } from "../store/slices/buylineSlice";
import { apiRequest } from "./api";

function addData(key, value) {
  localStorage.setItem(key, value);
}
function getData(key, value) {
  return localStorage.getItem(key);
}

async function buylineEnable() {
  console.log(fetchBuyline());
  return JSON.parse(localStorage.getItem("buyline_enable")) == true;
}

function isTest() {
  return JSON.parse(getData("test_order"));
}

export { addData, getData, buylineEnable, isTest };
